import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Image } from '@uz/unitz-components-web/Image';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CourseTemplateAttachments from '@uz/unitz-tool-components/CourseTemplateAttachments';
import CourseTemplateLinkUrls from '@uz/unitz-tool-components/CourseTemplateLinkUrls';
import CourseTemplateLessonDescription from '@uz/unitz-tool-components/CourseTemplateLessonDescription';

// const CustomList = styled.div`
//   p {
//     color: ${gstyles.colors.main};
//     ul {
//       margin-left: 20px;
//       li {
//         color: ${gstyles.colors.main};
//         list-style-type: disc;
//         margin-left: 0;
//       }
//     }
//   }
// `;

export const View8 = (props) => {
  const { idx } = props;
  return (
    <DIV className="component">
      <div>
        <div>
          <Row
            gutter={[
              { xs: 24, md: 18, lg: 16 },
              { xs: 12, md: 0 },
            ]}
          >
            <Col xs={24} md={4}>
              <div className="bg-background2 rounded-xl">
                <ResponsiveProps xs={{ height: 214 }} md={{ height: 152 }} lg={{ height: 152 }}>
                  {(resProps) => (
                    <Image
                      style={{ objectFit: 'cover' }}
                      alt={`avt_${ctx.get('lesson_item.id')}`}
                      className="object-fill overflow-hidden rounded-xl"
                      src={CourseFormatter.avatarUrl(ctx)(ctx.get('lesson_item'))}
                      {...resProps}
                    />
                  )}
                </ResponsiveProps>
              </div>
            </Col>
            <Col xs={24} md={20}>
              <div className="flex flex-col space-y-1">
                <h3 className="text-xl font-semibold text-main">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.lesson')} {idx + 1}{' '}
                  {ctx.get('lesson_item.name') ? `| ${ctx.get('lesson_item.name')}` : ''}{' '}
                  {/* {!!ctx.get('lesson_item.name') || ' '} */}
                </h3>

                <CourseTemplateLessonDescription data={ctx.get('lesson_item.description')} />
                <CourseTemplateAttachments data={ctx.get('lesson_item')} />
                {!!ctx.get('lesson_item.link_urls.length') && <CourseTemplateLinkUrls data={ctx.get('lesson_item')} />}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
