import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import _ from 'lodash';
import Button from '@uz/unitz-components-web/Button';
import { Row, Col } from 'antd';
import Link from '@uz/unitz-components-web/Link';
import CourseTemplateAttachments from '@uz/unitz-tool-components/CourseTemplateAttachments';
import CourseTemplateBrief from '@uz/unitz-tool-components/CourseTemplateBrief';
import CourseTemplateDescription from '@uz/unitz-tool-components/CourseTemplateDescription';
import CourseTemplateLinkUrls from '@uz/unitz-tool-components/CourseTemplateLinkUrls';
import CourseTemplateLessons from '@uz/unitz-tool-components/CourseTemplateLessons';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <DIV className="isLoading">
          <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
            <LoadingScreen />
          </div>
        </DIV>
        <DIV className="notLoading">
          <div className="md:p-4 h-full">
            <div className="p-4 bg-white500 rounded-lg shadow-xl">
              <div className="mb-0 md:mb-4 text-main uppercase font-semibold text-base md:text-2xl">
                {!!ctx.get('@item.name') ? ctx.get('@item.name') : ''}
              </div>
              <CourseTemplateBrief data={ctx.get('@item')} />
              {!!ctx.get('@item.description') && (
                <CourseTemplateDescription
                  data={ctx.get('@item.description')}
                  title={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Detail.about_title')}
                />
              )}
              {!!ctx.get('@item.materials.length') && (
                <div className="mb-2">
                  <div className="mb-4 text-xl text-main font-semibold">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Detail.materials')}
                  </div>
                  <CourseTemplateAttachments data={ctx.get('@item')} />
                </div>
              )}
              {!!ctx.get('@item.link_urls.length') && <CourseTemplateLinkUrls data={ctx.get('@item')} />}
              {!!ctx.get('@item.course_template_lessons') && (
                <div className="my-8">
                  <CourseTemplateLessons />
                </div>
              )}
              <Row justify="end">
                <Col xs={24} md={12}>
                  <ResponsiveProps
                    xs={{ style: { flexDirection: 'column-reverse' } }}
                    md={{ style: { flexDirection: 'row' } }}
                  >
                    {(resProps) => (
                      <Row
                        gutter={[
                          { xs: 12, md: 8 },
                          { xs: 12, md: 8 },
                        ]}
                        {...resProps}
                      >
                        <Col xs={24} md={12}>
                          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplates')}>
                            <Button block name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                              <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.back')}</span>
                            </Button>
                          </Link>
                        </Col>
                        <Col xs={24} md={12}>
                          <Link
                            to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateEdit', {
                              id: ctx.get('getTemplateId'),
                            })}
                          >
                            <Button type="primary" className="w-full" block>
                              <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.edit')}</span>
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </ResponsiveProps>
                </Col>
              </Row>
            </div>
          </div>
        </DIV>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
