import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import CourseTemplateLessonItemX from '@uz/unitz-tool-components/CourseTemplateLessonItemX';
import { Row, Col } from 'antd';

export const View8 = () => {
  return (
    <DIV className="component">
      {!!ctx.get('@item.course_template_lessons.length') && (
        <>
          <div className="mb-4 text-xl text-main font-semibold">
            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Detail.lessons')}
          </div>
          <Row gutter={[0, 32]}>
            {_.map(ctx.get('@item.course_template_lessons', []), (item, index) => {
              return (
                <Col span={24} key={index}>
                  <DIV>
                    {ctx.debug(() => {
                      ctx.set('lesson_item', item);
                    })}
                    <CourseTemplateLessonItemX idx={index} />
                  </DIV>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </DIV>
  );
};

export default displayName(View8);
