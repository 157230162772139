import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import { Image } from '@uz/unitz-components-web/Image';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

export const CourseTemplateBrief = (props) => {
  const { data } = props;

  return (
    <DIV className="listItem">
      <div className="flex flex-col p-0 relative">
        <div className="my-6 mb-4">
          {_.get(data, 'video_url') ? (
            <LinkPlayer to={_.get(data, 'video_url', '')}>
              {!!_.get(data, 'photo_url') && (
                <div className="relative">
                  <ResponsiveProps
                    xs={{ style: { maxWidth: '100%' }, height: 214 }}
                    md={{ style: { maxHeight: '446px', maxWidth: '100%' }, height: 446 }}
                  >
                    {(resProps) => (
                      <Image
                        alt="photo"
                        // className="overflow-hidden rounded-lg"
                        style={{ objectFit: 'contain' }}
                        src={CourseFormatter.avatarUrl(ctx)(data)}
                        {...resProps}
                      />
                    )}
                  </ResponsiveProps>

                  <div className="absolute flex justify-center items-center border-white400 bg-black200 border-4 opacity-90 hover:opacity-80 duration-400 h-16 w-16 rounded-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    {gstyles.icons({ name: 'play', size: 32, fill: gstyles.colors.white500 })}
                  </div>
                </div>
              )}
            </LinkPlayer>
          ) : (
            <ResponsiveProps
              xs={{ style: { maxHeight: '214px', maxWidth: '100%', objectFit: 'contain' }, height: 214 }}
              md={{ style: { maxHeight: '446px', maxWidth: '100%', objectFit: 'contain' }, height: 446 }}
            >
              {(resProps) => (
                <Image
                  alt="photo"
                  className="overflow-hidden rounded-lg"
                  src={CourseFormatter.avatarUrl(ctx)(data)}
                  {...resProps}
                />
              )}
            </ResponsiveProps>
          )}
        </div>
      </div>
    </DIV>
  );
};

export default CourseTemplateBrief;
